import { CBContext, CBEventInfo, Composition, DebugEvent, InsLeaves, TagItem } from "../../codebricks-runtime/CBModels";
import { BrickInfo, createBrickInfoTree, EscapeHtml, FindBrick } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { TemplateUtil } from "../../codebricks-runtime/TemplateUtil";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_visual_debugger_webcomponent extends HTMLElement {
    ci: web_c_visual_debugger | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
            let idx = this.getAttribute('idx') as string;
            let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_visual_debugger(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-visual-debugger', c_visual_debugger_webcomponent);

export class web_c_visual_debugger extends CodeBrick {

    element: HTMLElement;
    container: HTMLElement;
    initialized = false;
    include_sub_compositions = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;  

        this.container = document.createElement("div");
        this.container.id = this.brick_id;
        this.element.appendChild(this.container);
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<void> {
        //console.log("c-visual-debugger input "+input+" cfg "+JSON.stringify(cfg));

        if(input == "cfg") {
            if(!this.initialized) {
                this.create_modal();
                this.initialized = true;
            }

            this.include_sub_compositions = cfg.include_sub_compositions || false;

            let brick_info_tree = cfg.brick_info_tree;
            if(brick_info_tree && brick_info_tree.blueprint) {
                //Received from server side
            }
            else if(this.context.composition_runners["_1"]) {
                //Use current local client side
                brick_info_tree = createBrickInfoTree(this.context.composition_runners["_1"]);
            }

            let search = (cfg.search || "").toLowerCase();

            if(brick_info_tree) {
                this.renderBrickInfoTree(brick_info_tree, search);
            }
        }
    }

    renderBrickInfoTree(brick_info_tree: BrickInfo, search: string) {
        this.container.innerHTML = "";
        let container = document.getElementById(this.brick_id);
        if(container) {
            this.renderBrickInfoTreeRecurse(brick_info_tree, 0, search);
        }
    }

    renderBrickInfoTreeRecurse(brick_info: BrickInfo, nest: number, search: string) {
        if(!brick_info.blueprint) {
            console.error("renderBrickInfoTreeRecurse: no blueprint "+JSON.stringify(brick_info));
            return;
        }

        this.CheckFillJitGenerator(brick_info);

        let is_sub = brick_info.blueprint.name.indexOf("-") != -1;
        let sub_inc = 0;
        let bi_container = null as any;
        if((!is_sub || this.include_sub_compositions) && (search == "" || brick_info.blueprint.name.toLowerCase().indexOf(search) != -1)) {
            let NEST_MARGIN = 10;
            bi_container = document.createElement("div");
            bi_container.style.marginLeft = (nest *  NEST_MARGIN) + "px";
            
            let bi_heading = document.createElement("div");
            bi_heading.classList.add("c-vd-brick");
            
            let bi_name = document.createElement("div");
            bi_name.classList.add("c-vd-brick-name");
            bi_name.innerHTML = brick_info.blueprint.name + (brick_info.dci !== null ?  (" " + brick_info.dci) : "");
            bi_heading.appendChild(bi_name);

            let brick_name = brick_info.blueprint.name;
            let brick_type = brick_info.blueprint.type;

            bi_name.addEventListener("click", async function() {
                let edit_buttons_container = document.getElementById("cb_0_brick_tree-component_tree_container");
                if(edit_buttons_container) {
                    let targets = edit_buttons_container.querySelectorAll('[label="'+brick_name+'"]');
                    if(targets && targets.length > 0) {
                        //@ts-expect-error
                        targets[0].click();
                    }
                    else {

                        let sub_brick_info = "<p>This brick is in a sub composition. To edit the brick, please open the sub composition.</p><p> These are the settings for this brick:</p>";

                        let ins_str = JSON.stringify(brick_info.blueprint.ins || {}, null, 2);
                        ins_str = CBWebUtil.escapeHtml(ins_str);
                        ins_str = ins_str.replaceAll("\\n", "\n");
                        ins_str = "<pre>" + ins_str + "</pre>";

                        await self.show_modal(brick_name + "<p>" + brick_type + "</p>", sub_brick_info + ins_str); 
                    }
                }
            });

            let bi_type = document.createElement("div");
            bi_type.classList.add("c-vd-type");
            bi_type.innerHTML = "(" + brick_info.blueprint.type + ")";
            bi_heading.appendChild(bi_type);

            let bi_outs_btn = document.createElement("button");
            bi_outs_btn.classList.add("c-vd-btn");
            bi_outs_btn.innerHTML = "outs";
            bi_heading.appendChild(bi_outs_btn);

            if(brick_info.debug_ios.last_outs) {
                bi_outs_btn.classList.add("c-vd-btn-active");
            }

            let self = this;
            bi_outs_btn.addEventListener("click", async function() { 
                let outs = "No output";
                if(brick_info.debug_ios.last_outs !== undefined) {
                    let outs_without_brick_info = {} as any;
                    let last_outs = brick_info.debug_ios.last_outs || {}
                    for(let p in last_outs) {
                        if(p != "@brick_info_tree") {
                            outs_without_brick_info[p] = last_outs[p];
                        }
                    }
                    outs = "<div>Note: \"@\" is the default output</div><pre>" + JSON.stringify(outs_without_brick_info, null, 2) + "</pre>";
                }

                await self.show_modal(brick_info.blueprint.name+" outs", outs); 
            });

            bi_container.appendChild(bi_heading);

            for(let input in brick_info.parsed_ins) {
                let in_element = document.createElement("div");
                in_element.classList.add("c-vd-in");
                in_element.style.marginLeft = (2 *  NEST_MARGIN) + "px";

                let bi_ins_btn = document.createElement("button");
                bi_ins_btn.classList.add("c-vd-btn");
                bi_ins_btn.classList.add("c-vd-btn-in");
                bi_ins_btn.innerHTML = input;
                in_element.appendChild(bi_ins_btn);

                let tags_container = document.createElement("div");
                if(brick_info.debug_ios.last_ins && brick_info.debug_ios.last_ins[input] !== undefined) {
                    bi_ins_btn.classList.add("c-vd-btn-active");
                }
                bi_ins_btn.addEventListener("click", async function() { 
                    let in_val = "<h2>Template</h2>";

                    let template = CBWebUtil.escapeHtml(JSON.stringify(brick_info.blueprint.ins[input], null, 2));
                    template = template.replaceAll("\\n", "\n");
                    in_val += "<pre>" + template + "</pre>";

                    in_val += "<h2>Value</h2>";
                    
                    if(brick_info.debug_ios.last_ins && brick_info.debug_ios.last_ins[input] !== undefined) {

                        let json = JSON.stringify((brick_info.debug_ios.last_ins[input] || {}), null, 2);
                        json = CBWebUtil.escapeHtml(json);
                        json = json.replaceAll("\\n", "\n");

                        in_val += "<pre>" + json + "</pre>";
                    }
                    else {
                        in_val += "<p><b>Unresolved:</b> Waiting for input(s) or tag conditions not passed</p>";
                    }
    
                    await self.show_modal(brick_info.blueprint.name+" (" + brick_info.blueprint.type + ")<p><b>"+input+"</b> input - last value received:</p>", in_val); 
                });

                for(let parsed_leaf of brick_info.parsed_ins[input].leaves) {
                    for(let tpart of parsed_leaf.parts) {
                        if(tpart.tag) {
                            let ti_container = this.renderTagItem(tpart.tag);
                            ti_container.style.marginLeft = (NEST_MARGIN) + "px";
                            tags_container.appendChild(ti_container);
                        }
                    }
                }
                in_element.appendChild(tags_container);

                bi_container.appendChild(in_element);
            }
            
            sub_inc = 1;

            this.container.appendChild(bi_container);
        }

        if(brick_info.contains) {
            for(let c of brick_info.contains) {
                this.renderBrickInfoTreeRecurse(c, nest + sub_inc, search);
            }
        }
    }

    CheckFillJitGenerator(brick_info: BrickInfo) {
        if(brick_info.blueprint.type != "c-jit-generator") {
            return;
        }

        for(let rcid in this.context.composition_runners) {
            let runner = this.context.composition_runners[rcid];
            if(runner.context_codebrick && runner.context_codebrick.blueprint.name == brick_info.blueprint.name) {
                let jit_tree = createBrickInfoTree(runner);
                if(jit_tree) {
                    brick_info.contains = [jit_tree];
                }
                return;
            }
        }
    }

    renderTagItem(ti: TagItem) {
        let ti_container = document.createElement("div");
        ti_container.classList.add("cvd-ti");

        let tag_string = TemplateUtil.UnParseTagItem(ti, false) ||  "";;

        let tag_container = document.createElement("div");
        tag_container.innerHTML = this.UnParseTagItem(ti, false);
        ti_container.appendChild(tag_container);

        let tag_btn = document.createElement("button");
        tag_btn.classList.add("c-vd-btn-tag");
        tag_btn.innerHTML = `{{<img src="https://s2.svgbox.net/hero-outline.svg?ic=eye&color=02366d"/>}}`;
        ti_container.appendChild(tag_btn);

        if(ti.val !== undefined) {
            tag_btn.classList.add("c-vd-btn-active");
        }

        let self = this;
        tag_btn.addEventListener("click", async function() {
            let in_val = "";
            
            if(ti.val !== undefined) {
                in_val += "<pre>" + JSON.stringify(ti.val, null, 2) + "</pre>";
            }
            else {
                in_val += "<p><b>Unresolved:</b> Waiting for input(s) or tag conditions not passed</p>";
            }

            await self.show_modal("Tag Value<p>" + tag_string + "</p>", in_val); 
        });

        return ti_container;
    }


    UnParseTagItem(ti: TagItem, is_bracket = false) {

        let ret = "";
        if(ti.type[0] == "{") {
            ret += `<span ${ti.val !== undefined ? `class="cvd-term-active"`:""}>` + (is_bracket ? "(" : ("{" + ti.type)) + `</span>`;
            let first = true;
            if(ti.items) {
                for(let section of ti.items) {
                    if(first) {
                        first = false;
                    }
                    else if(section.type == ";") {
                        ret += ";";
                    }
                    else {
                        ret += ",";
                    }
                    if(section.type[0] == "{") {
                        ret += this.UnParseTagItem(section);
                    }
                    else if(section.type == ";") {
                        ret += `<span ${ti.val !== undefined ? `class="cvd-term-active"`:""}>` + section.slice + `</span>`;
                    }
                    else {
                        ret += this.UnParseSection(section);
                    }
                }       
            }
            ret += `<span ${ti.val !== undefined ? `class="cvd-term-active"`:""}>` + ( is_bracket ? ")" : "}}" ) + `</span>`;

            return ret;
        }    
        return "";
    }

    UnParseSection(section: TagItem) {
        let res = `<span ${section.val !== undefined ? `class="cvd-term-active"`:""}>`;
        if(section.items) {
            for(let t = 0; t < section.items.length; t++) {
                let term = section.items[t];
                if(term.type == 'p') {
                    //res += term.slice;

                    let json = "<p><b>Unresolved:</b> No value received</p>";
                    if(term.val !== undefined) {
                        json = JSON.stringify(term.val, null, 2);
                        //json = CBWebUtil.escapeHtml(json);
                        json = json.replaceAll("\"",  "\\\"");
                        json = json.replaceAll("\n",  "\\n");
                    }

                    res += `<span class="cvd-term${term.val !== undefined ? " cvd-term-active":""}" onclick='codebricks_context.bricks["${this.brick_id}"].show_modal("Term Value<p>${term.slice}</p>","<pre>${json}</pre>")'>${term.slice}</span>`;
                }
                else if(term.type == "s") {
                    res += "'" + CBWebUtil.escapeHtml(term.slice) + "'";
                }
                else if(term.type == "n" || term.type == "b") {
                    res += term.slice;
                }
                else if(term.type == "0") {
                    res += "null";
                }
                else if(term.type[0] == "{") {
                    res += TemplateUtil.UnParseTagItem(term, true);
                }
                else {
                    if(term.type == "!") {
                        res += "!=";
                    }
                    else {
                        res += term.type;
                    }
                }
            }
        }
        return res + "</span>";
    }

    create_modal() {
        let modal_html = `<cci-modal id="${this.brick_id}$cvd_modal" ins='{"cfg":{ "title":"View", "cancel_button": "Close" }}'></cci-modal>`;
        this.element.insertAdjacentHTML("beforeend", modal_html);
    }

    async show_modal(title: string, content: string) {
        await (<any>window).so_bricks[this.brick_id+"$cvd_modal_brick"].cb_event("cfg", { "title": title, "cancel_button": "Close" });
        let modal_body = document.getElementById(this.brick_id+"$cvd_modal_brick$body");
        if(modal_body) {
            modal_body.innerHTML = `<div style="overflow:auto">${content}</div>`;
        }
        await (<any>window).so_bricks[this.brick_id+"$cvd_modal_brick"].cb_event("show", 1);
    }

    cb_initial_cement(cements: { [child_idx: number]: any }) {
        
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
    }
    cb_snapshot() {}

}