import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { cb_tooltip } from "../controls/cb_tooltip";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_checkbox_webcomponent extends HTMLElement {
    ci: web_c_checkbox | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_checkbox(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-checkbox', c_checkbox_webcomponent);

export class web_c_checkbox extends CodeBrick {
    element: HTMLElement;
    initialised = false;
    value = false;
    value_object = {} as any;

    //shadowroot: ShadowRoot;
    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;
        //this.shadowroot = this.attachShadow({ mode: 'open' });

        //let self = this;


    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksInput "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.cfg)+" => "+JSON.stringify(cfg));

        if(input == 'value') {
            if(cfg === "0" || cfg === "false") {
                this.value = false
            }
            else {
                this.value = Boolean(cfg);
            }
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                
                input.checked = this.value ? true : false
                //this.cb_emit({"": this.value}, false); //trigger false, for the dest to keep the input value change.
                //this.cb_emit("", this.value, trigger); //trigger false, for the dest to keep the input value change.

                //return {"@": input.checked };
            }

            if(!cfg.dont_send_intial_value) {
                return { "@" : this.value ? true : false };
            }
        }
        else if(input == 'cfg') {
            if(!this.initialised) {

                this.element.innerHTML = 
                `<label class="c-checkbox-container" id="${this.brick_id}$container" for="${this.brick_id}" >  
                    <input type="checkbox" id="${this.brick_id}" class="c-checkbox"/>
                    <span class="c-checkbox-checkmark"></span>
                    <span class="c-checkbox-label">${cfg.label || ''}</span>
                </label>`;
           
                this.initialised = true;
                let input_element = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
                let self = this;
                if(input_element) {
                    input_element.addEventListener('change', function(e: any) {

                        //console.log("checkbox changed to " + input_element.checked);
                        self.value = input_element.checked;
                        self.value_object = self.value_object || {};
                        self.value_object.checked = input_element.checked;
                        self.cb_emit({ "@": input_element.checked, "@value_object": self.value_object, "@user_changed": input_element.checked });
                    });
                }
            }
            let input_element = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            
            let container = document.getElementById(`${this.brick_id}$container`);
            if(input_element && container) {
                input_element.checked = this.value ? true : false;
            
                if(cfg.disabled) {
                    input_element.disabled = true;            
                    container.classList.add("c-checkbox-disabled");
                }
                else {
                    input_element.disabled = false;   
                    container.classList.remove("c-checkbox-disabled");
                }
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
                CBWebUtil.ApplyElementStyles(container.firstElementChild as HTMLElement, cfg, "label");
                CBWebUtil.ApplyElementStyles(container.lastElementChild as HTMLElement, cfg, "checkmark");
            }
   
            if(cfg.value_object) {
                if(typeof cfg.value_object == "object") {
                    this.value_object = cfg.value_object;
                }
                else {
                    console.error(this.blueprint.name+": c-checkbox value_object must be an object");
                }

            }

            if(container) {
                // if(cfg.style_classes) {
                //     for(let cl of cfg.style_classes) {
                //         container.classList.add(cl);
                //     }
                // }

                // if(cfg.style) {
                //     for(let v in cfg.style) {
                //         container.style.setProperty(v, cfg.style[v]);
                //     }
                // }

                if(cfg.hidden) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }

                if(cfg.tooltip && cfg.tooltip.text) {
                    container.setAttribute("tooltip", cfg.tooltip.text);
                    cb_tooltip.set_tooltip(container);
                }
            }

        }
    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): void {
        if(status == "required") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.add("required");
            }
        }
        else if(status == "") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).classList.remove("required");
            }
        }
    }

    cb_snapshot() {}
}
