import { setLocale } from "./CBFormatting.js";
import { Brick, CBContext, CBEventInfo, CodeBricksResult, Composition, DebugLog, EmitLog, InsLeaves, TagItem } from "./CBModels.js";
import { Clone, ObjectDeepMerge } from "./CBUtil";
import { CodeBrick } from "./CodeBrick.js";
import { LeafParser } from "./LeafParser";
import { LinkMapper } from "./LinkMapper";
import { TemplateUtil } from "./TemplateUtil.js";

export class CBCompositionRunner {

    cid = '_';
    compos: Composition | undefined;
    context: CBContext;
    composition: string;

    cn_out_data = {} as any;
    context_codebrick: CodeBrick | undefined;

    reverse_link_map = null as { [source: string] : { [dest:string]: string } } | null;

    nsods = {} as { [ns:string] : { [name: string] : { [output: string] : { r: number, s: number, d: any } } } };
    run_id = 0;
    seq = 0;
                                           
    run_list = {} as { [context: string]: { [brick_id: string]: { [input: string]: boolean } } };

    static MAX_ITERATIONS_PER_EMIT = 3000;

    debugging_enabled = false;

    deferred_emits = {} as { [brick_id:string] : { data: any, dc?: string | undefined } };

    //get_debug_log_callback?: (() => Promise<DebugLog | null>);
    save_debug_log_callback?: ((led: DebugLog) => Promise<CodeBricksResult>);
    busy_saving_debug_log = false;

    constructor(composition: string, context: CBContext, 
        //get_debug_log_callback: (() => Promise<DebugLog | null>),
        save_debug_log_callback: ((led: DebugLog) => Promise<CodeBricksResult>)) 
    {
        this.composition = composition;
        this.context = context;
        //this.get_debug_log_callback = get_debug_log_callback;
        this.save_debug_log_callback = save_debug_log_callback;
    }

    static init_context(context: CBContext) {
        context.cb_cnid = context.cb_cnid || 0;
        context.bricks = context.bricks || {};
        context.composition_runners = context.composition_runners || {};
        context.compositions = context.compositions || {};
        context.blueprints = context.blueprints || {};
        context.ci_idx = context.ci_idx || 0;
        context.dc_tree = context.dc_tree || {};
        context.debug_log = { emits: [], last_emit_data: {} };
        context.last_emit_data = context.last_emit_data || {};

        context.status = function(brick_id: string, indent = '', done_names = {} as { [name: string] : boolean }) : void {
            // if(name === undefined || done_names[name]) {
            //     console.log("Status hit circular structure on "+name+" STOPPING");
            //     return;
            // }
            done_names[brick_id] = true;
            let found = false;
            for(let c in context.bricks) {
                let ci = context.bricks[c]; 
                if(!ci.blueprint.name) {
                    continue;
                }
                if(ci.brick_id == brick_id) {
                    found = true;

                    console.log("Found "+ci.brick_id);
                    console.log(indent+" - last ins: "+JSON.stringify(ci.debug_ios?.last_ins))
                    
                    for(let input in ci.blueprint.ins) {
                        if(ci.unresolved[input] && ci.unresolved[input].length > 0) {
                            console.log(indent + "input "+input+" Unresolved - waiting for:");
                            for(let u of ci.unresolved[input]) {
                                if(!u) {
                                    continue;
                                }
                                console.log(indent+" "+u);

                                //let u2 = u.split(".")[0];
                                //console.log(context.status(u2, indent+'  ', done_names));
                            }
                        }
                        else {
                            console.log(indent + " - input "+input+" Resolved;");
                        }
                    }

                    let cid = "_" + ci.brick_id.split("_")[1];
                    let last_emit_data = context.last_emit_data[cid][ci.blueprint.name];

                    console.log(indent + "last_emit_data: "+JSON.stringify(last_emit_data));

                }

            }

            if(!found) {
                console.log(indent + " Could not find "+name);
            }

        }

    }

    init_composition(compos: Composition, args_object: any, reuse_cid = "" as string || null) {

        if(reuse_cid) {
            if(this.context.bricks) {
                let pf = "cb"+reuse_cid+"_";
                for(let b in this.context.bricks) {
                    if(b.startsWith(pf)) {
                        delete this.context.bricks[b];
                    }
                }
            }
        }

        CBCompositionRunner.init_context(this.context);

        compos.composition = this.composition;
        this.compos = compos;
        
        if(reuse_cid) {
            this.cid = reuse_cid;
        }
        else {
            this.cid = '_' + this.context.cb_cnid;
            this.context.cb_cnid++;
        }
        this.context.composition_runners[this.cid] = this;
        this.context.compositions[this.cid] = this.compos;
        this.context.blueprints[this.cid] = {} as { [name: string] : Brick };
        this.context.dc_tree[this.cid] = {};
        this.context.last_emit_data[this.cid] = this.context.last_emit_data[this.cid]  || {};
        this.nsods = {};
        if(this.compos.prep) {
            this.nsods = this.compos.prep.nsods || {};
        }
        this.nsods[""] = this.nsods[""] || (this.nsods["$"] != undefined ? Object.create(this.nsods["$"]) : {});

        this.register_component(this.context.blueprints[this.cid], compos, args_object);

        //create link_map
        LinkMapper.Map(this.context.blueprints[this.cid]);
    }

    register_component(blueprints: { [name: string] : Brick }, ci: Brick, args_object: any) {
        blueprints[ci.name] = ci;
        ci.parsed_ins = {} as InsLeaves;
        ci.parsed_ins = LeafParser.Parse(ci.ins);

        if(ci.type == "cc-use") { //This is cc-use and not cc-unit, because the cc-use's outs get mapped to the cc-unit when nested
            ci.ins = ci.ins || {};
            ci.ins.args = args_object;
        }

        if(ci.contains) {
            for(let loc of ci.contains) {
                if(loc) {
                    this.register_component(blueprints, loc, args_object);
                }
            }
        }
    }

    get_brick_ids(brick_name: string, dc: string) : string[] {

        //console.log("1 getTargets destname "+destname+" source_dc "+source_dc);

        let ret = [] as string[];

        if(this.context.dc_tree[this.cid][brick_name]) {
            let dcs = dc.split("--");
            let tree_at = this.context.dc_tree[this.cid][brick_name];

            let at_dc = "";

            for(let d = 1; d < dcs.length; d++) {
                let dcpart = "--"+dcs[d];
                if(tree_at[dcpart]) {
                    tree_at = tree_at[dcpart];
                    at_dc += dcpart;
                }
                else {
                    //We don't have such a deep dc, so its for us.
                    break;
                }
            }

            this.getChildTargets(tree_at, at_dc, brick_name, ret);
            return ret;
        }
        else {
            ret.push("cb"+this.cid + '_'+brick_name);
            return ret;
        }
    }

    getChildTargets(tree_at: any, at_dc: string, destname: string, ret: string[]) {
        let has_childs = false;
        for(let d in tree_at) {
            this.getChildTargets(tree_at[d], at_dc + d, destname, ret);
            has_childs = true;
        }

        if(!has_childs) {
            let brick_id = "cb"+this.cid + '_' + destname + at_dc;
            ret.push(brick_id);
        }
    }

    getTargetsRecurse(tree_at: any, at_dc: string, at_dc_part: string, ret: string[]) {
        for(let d in tree_at[at_dc_part]) {
            let brick_id = "cb"+this.cid + '_' + at_dc + d;
            if(this.context.bricks[brick_id]) {
                ret.push(brick_id);
            }
            else if(tree_at[at_dc_part]) {
                this.getTargetsRecurse(tree_at[at_dc_part], at_dc + d, d, ret);
            }
        }
    }

    getDcFrombrick_id(brick_id: string) {
        let dci = brick_id.indexOf('--');
        if(dci == -1) {
            return "";
        }
        return brick_id.substr(dci);
    }

    async send_initialisation_events(init_odata: any, source = "", source_output = "@", ) : Promise<any> {
        
        if(this.compos && this.compos.system_options && this.compos.system_options.locale) {
            setLocale(this.compos.system_options.locale);
        }

        if(this.compos && (this.compos.type == "cc-unit" || this.compos.type == "sc-unit" || this.compos.type == "sc-container")) {

            if(init_odata) {
                for(let source in init_odata) {
                    this.nsods[""][source] = this.nsods[""][source] || {};
                    for(let output in init_odata[source]) {
                        this.nsods[""][source][output] = this.nsods[""][source][output]  || {};
                        ObjectDeepMerge(this.nsods[""][source][output], { r: 0, s: 0, d: init_odata[source][output] });
                    }
                }
            }

        }

        let emit = null;
        if(this.debugging_enabled) {
            emit = {
                emitter: "INIT",
                emit_data: null,
                events: []
            };
            this.context.debug_log.emits.push(emit);

            // if(this.get_debug_log_callback) {
            //     let saved_debug_log = await this.get_debug_log_callback();
            //     if(saved_debug_log) {
            //         this.context.last_emit_data[this.cid] = saved_debug_log.last_emit_data;
            //     }
            // }
            //if(this.compos && this.compos.debug_log) {
                //this.context.last_emit_data[this.cid] = this.compos.debug_log.last_emit_data;
            //}
        }

        let info = {
            source_idx: 0,
            source: source,
            source_output: source_output
        } as CBEventInfo;
        await this.run_init_passes(info, "", emit);

        await this.run_deferred_emits();

        if(this.debugging_enabled) {
            this.context.debug_log.last_emit_data = this.context.last_emit_data[this.cid];
            if(this.save_debug_log_callback) {
                this.RunDebugLogCallback();
            }
        }

        if(this.compos != null) {
            return this.context.composition_runners[this.cid].cn_out_data;
        }

    }

    async run_deferred_emits() {
        let demits = this.deferred_emits;
        this.deferred_emits = {};
        if(demits) {
            for(let brickid in demits) {
                let brick = this.context.bricks[brickid];
                let de = demits[brickid];
                await brick.cb_emit(de.data, de.dc);         
            }
        }
    }

    async RunDebugLogCallback() {
        if(this.save_debug_log_callback) {
            let self = this;
            //prevent multiple calls from overwriting each other, causing corrupted json
            //The idea is that if there are multiple calls close together, they will all update context.debug_log
            //Then afterwards (when the setTimeout runs, it will send the upated value, guarded by busy_saving_debug_log to prevent concurrent writes)
            setTimeout(async function() { 
                if(self.save_debug_log_callback) {
                    if(!self.busy_saving_debug_log) {
                        self.busy_saving_debug_log = true; 
                        await self.save_debug_log_callback(self.context.debug_log);
                        self.busy_saving_debug_log = false;
                    }
                }
            }, 300);
        }
    }

    async run_init_passes(info: CBEventInfo, run_list_name: string, debuglog: EmitLog | null) {

        // if(debuglog) {
        //     console.log("run_init_passes "+debuglog.emitter);
        // }

        

        await this.run_pass(true, info, run_list_name, debuglog);
        await this.run_pass(false, info, run_list_name, debuglog);
    }

    //async run_pass(only_readies: boolean, source: string, source_output: string, run_list_name: string, debuglog: EmitLog | null) {
    async run_pass(only_readies: boolean, info: CBEventInfo, run_list_name: string, debuglog: EmitLog | null) {

        let promises = [] as any[];

        //console.log("run_pass "+only_readies);

        for(let brick_id in this.run_list[run_list_name]) {
            let ci = this.context.bricks[brick_id];
            if(ci) {
                for(var input in this.run_list[run_list_name][brick_id]) {
                    if(this.run_list[run_list_name][brick_id][input]) {
                        let input_ready = (!only_readies) || this.InputReady(ci, input);

                        if(input_ready) {

                            //remove from run list - done in run2

                            // if(ci.brick_id == "cb_0_layout-menu_lvl1_cd_flex") {//"cb_0_trend-table") {
                            //     console.log("RUN PASS "+only_readies+"  "+ci.brick_id+" RUN debuglog.emitter "+debuglog?.emitter);
                            // }

                            let source_data = null;
                            if(ci.dc && debuglog && debuglog.emit_data && Array.isArray(debuglog.emit_data)) {
                                let dc_parts = ci.dc.split("--");
                                let dc_idx = Number(dc_parts[dc_parts.length-1]);
                                source_data = debuglog.emit_data[dc_idx];
                            }

                            let output_info = Clone(info);

                            //run it
                            if(output_info.source && ci.blueprint.name == this.compos?.name) {
                                //io
                                promises.push(ci.run(input, output_info, source_data, debuglog));
                            }
                            else {
                                output_info.source = "";
                                output_info.source_output = "@";
                                promises.push(ci.run(input, output_info, source_data, debuglog));
                            }

                        }
                    }
                }    
            }
            else {
                //console.log("ci "+brick_id+" not found");
            }
        }
        let resolveds = await Promise.all(promises);
        for(let r of resolveds) {
            if(r) {
                return true;
            }
        }
        
        return false;
    }

    InputReady(ci: CodeBrick, input: string) {
        if(ci.parsed_ins && ci.parsed_ins[input]) {
            let leaves = ci.parsed_ins[input];
            if(leaves.has_tags)  {
                for(let leaf of leaves.leaves) {
                    for(let part of leaf.parts) {
                        if(part.tag) {
                            if(!this.TagItemReady(ci, part.tag)) {
                                return false;
                            }
                        }
                    }
                }      
            }    
        }
        return true;
    }

    TagItemReady(ci: CodeBrick, ti: TagItem): boolean {
        if(ti.type[0] == "p") {
            
            let s = ti.slice.split(".");
            let source = s[0];
            if(source[0] == "$") {
                return true;
            }

            let nsod = this.nsods[(ci.blueprint.nsid || "") + (ci.dc || "")];
            if(nsod === undefined) {
                return false;
            }

            let output = "@";
            if(s.length > 1 && s[1][0] == "@") {
                output = s[1];
            }

            //the nsod will have the original source name as in the tag (thats what nsod are for)
            if(nsod[source] && nsod[source][output]) {
                return true;
            }
            else {
                return false;
            }
        }
        else if(ti.items) {
            for(let i of ti.items) {
                let ready = this.TagItemReady(ci, i);
                if(!ready) {
                    return false;
                }
                if(i.type == ",") {
                    return ready; // This is for the {{,a}} scenario see "Pre resolve.docx"
                }
            }
        }
        return true;
    }

    set_cn_out_data(output: string, value: any) {
        this.cn_out_data["@"+output] = value;
        if(this.context_codebrick) {
            let emit_obj = {} as any;
            emit_obj["@"+output] = value;
            this.context_codebrick.cb_emit(emit_obj);
        }
    }

    print_unresolved() {
        console.log("Unresolved:");
        for(let c in this.context.bricks) {
            let ci = this.context.bricks[c]; 
            if(ci.cid != this.cid) {
                continue;
            }
            if(ci.parsed_ins) {
                for(let input in ci.parsed_ins) {
                    let in_leaves = ci.parsed_ins[input];
                    for(let leaf of in_leaves.leaves) {
                        for(let part of leaf.parts) {
                            if(part.tag) {
                                if(part.tag.val === undefined) {
                                    console.log(" "+ci.brick_id+" input "+input+" tag "+TemplateUtil.UnParseTagItem(part.tag));
                                }
                            }
                        }
                    }
                }
            }
        }
    }

}