import { CBContext, CBEventInfo } from "../../codebricks-runtime/CBModels";
import { EscapeRegExp } from "../../codebricks-runtime/CBUtil";
import { CodeBrick } from "../../codebricks-runtime/CodeBrick";
import { CBWebUtil } from "../controls/cb_web_util";

export class c_input_webcomponent extends HTMLElement {
    ci: web_c_input | undefined;
    constructor() {
        super();
    }
    connectedCallback() {
        if(!this.ci) {
            let context = (globalThis as any).codebricks_context;
            let cid = this.getAttribute('cid') as string;
            let name = this.getAttribute('name') as string;
            let dc = this.getAttribute('dc') as string;
                let idx = this.getAttribute('idx') as string;
                let container_id = this.getAttribute('container_id') as string;
            this.ci = new web_c_input(context, cid, name, dc, Number(idx), container_id, this);
        }
    }
    disconnectedCallback() {
        if(this.ci) {
            this.ci.destructor();
        }
    }
}
customElements.define('c-input', c_input_webcomponent);

export class web_c_input extends CodeBrick {

    element: HTMLElement;
    initialised = false;
    value = "";
    label = "";
    placeholder = "";

    validation = null as any;

    validate = false;

    constructor(context: CBContext, cid:string, name: string, dc: string, idx: number, container_id: string, element: HTMLElement) {
        super(context, cid, name, dc, idx, container_id);
        this.element = element;

        this.element.innerHTML = `<div class="c-input-container hidden" id="${this.brick_id}$container">
                <label class="c-input-label hidden" id="${this.brick_id}$label" for="${this.brick_id}"></label>
                <div class="c-input-wrap" id="${this.brick_id}$wrap"><input type="text" id="${this.brick_id}" name="" class="c-input" placeholder=""/></div>
                </div><div class="c-input-validation-message" id="${this.brick_id}$validation" style="display:none"></div>`;
    }

    async cb_event(input: string, cfg: any, info: CBEventInfo): Promise<any> {
        //console.log("CodeBricksInput "+this.blueprint.name+" cb_event "+input+" "+JSON.stringify(this.blueprint.ins)+" => "+JSON.stringify(cfg));

        if(input == 'value') {
            this.value = cfg;
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                input.value = this.value;

                this.clearValidation();

                return {"@": this.value, "@live": this.value };
            }
        }
        else if(input == 'cfg') {
            let initializing = false;
            
            if(cfg.label === undefined || cfg.label === null) {
                cfg.label = '';
            }

            initializing = true;
            let postfix = "";
            let input_postfix_class = "";
            if(cfg.postfix) {
                postfix = `<div class="c-input-postfix">${cfg.postfix}</div>`;
                input_postfix_class = "c-input-postfix-input";
            }
            let prefix = "";
            let input_prefix_class = "";
            if(cfg.prefix) {
                prefix = `<div class="c-input-prefix">${cfg.prefix}</div>`;
                input_prefix_class = "c-input-prefix-input";
            }

            // let classes = '';
            // if(cfg.style_classes) {
            //     for(let cl in cfg.style_classes) {
            //         classes += " " + cfg.style_classes[cl];
            //     }
            // }

            // let style = "";
            // if(cfg.style) {
            //     for(let v in cfg.style) {
            //         style += v+':'+cfg.style[v]+";"
            //     }
            // }

            // let maxlength_attribute = "";
            // if(cfg.validation && cfg.validation.maxlength) {
            //     maxlength_attribute = `maxlength="${cfg.validation.maxlength}"`;
            // }

            // this.element.innerHTML = `<div class="c-input-container" id="${this.brick_id}$container">
            // <label class="c-input-label${cfg.label==''?" hidden":""}" id="${this.brick_id}$label" for="${this.brick_id}">${cfg.label || ''}</label>
            // ${prefix}<div class="c-input-wrap" id="${this.brick_id}$wrap"><input type="${cfg.type || 'text'}" id="${this.brick_id}" name="${cfg.label}" class="c-input${input_postfix_class}${input_prefix_class}" placeholder="${cfg.placeholder || cfg.label || ''}" ${maxlength_attribute}/></div>${postfix}
            // </div><div class="c-input-validation-message" id="${this.brick_id}$validation" style="display:none"></div>`;

            let label = document.getElementById(`${this.brick_id}$label`) as HTMLLabelElement;
            label.innerHTML = cfg.label;
            if(cfg.label == "") {
                label.classList.add("hidden");
            }
            else {
                label.classList.remove("hidden");
            }

            let wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(prefix) {
                wrap.insertAdjacentHTML('beforebegin', prefix);
                input.classList.add(input_prefix_class);
            }
            if(postfix) {
                wrap.insertAdjacentHTML('afterend', postfix);
                input.classList.add(input_postfix_class);
            }
            
            input.placeholder = cfg.placeholder || cfg.label || '';
            if(cfg.validation && cfg.validation.maxlength) {
                input.maxLength = cfg.validation.maxlength;
            }
            input.type = cfg.type;
            input.name = cfg.label;
            input.disabled = cfg.disabled;  
            
            this.initialised = true;
            this.label = cfg.label;
            this.placeholder = cfg.placeholder;
            let self = this;
            if(input) {
                input.addEventListener('change', function() {
                    self.value = input.value;
                    self.cb_emit({"@": input.value, "@user_changed": input.value, "@live": input.value });
                });
                if(this.blueprint.targets && this.blueprint.targets["@live"]) {
                    input.addEventListener('input', function(event) {
                        if(self.validate) {
                            self.cb_validate();
                                                        }
                        self.cb_emit({ "@live": input.value });
                    });
                }
                input.addEventListener('keyup', function (e) {
                    if (e.key === 'Enter') {
                        self.form_value_submit();
                    }
                });
            }
            

            if(this.label != cfg.label) {
                let label_element = document.getElementById(`${this.brick_id}$label`) as HTMLLabelElement;
                label_element.innerHTML = cfg.label;
                this.label = cfg.label;
                if(cfg.label == "") {
                    label_element.classList.add("hidden");
                }
                else {
                    label_element.classList.remove("hidden");
                }
                CBWebUtil.ApplyElementStyles(label_element, cfg, "label");
            }
            
            if(input) {
                CBWebUtil.ApplyElementStyles(input, cfg, "input");
            }
            
            if(cfg.disabled) {
                input.disabled = true;
            }

            let container = document.getElementById(this.brick_id+"$container");
            if(container) {
                if(cfg.hidden) {
                    container.classList.add("hidden");
                }
                else {
                    container.classList.remove("hidden");
                }
                CBWebUtil.ApplyElementStyles(container, cfg, "container");
            }

            this.validation = cfg.validation;
            this.clearValidation();

            if(this.validation && this.validation.invalidate_msg) {
                let input_wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
                if(input_wrap) {
                    input_wrap.classList.add("c-input-validation-failed");
                }
                let validation_message_container = document.getElementById(`${this.brick_id}$validation`);
                if(validation_message_container) {
                    validation_message_container.innerHTML = this.validation.invalidate_msg;
                    validation_message_container.style.display = "block";
                }
            }

            if(initializing) {
            //    return {"@": this.value };
            }

        }
        else if(input == "focus") {
            if(cfg) { //have to check if truthy, else last input on page will always get focus, from initial events.
                let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
                if(input) {
                    input.focus();
                }
            }
        }

    }
    cb_initial_cement(cements: { [child_idx: number]: any }) {
    }
    cb_update_cement(child_idx: number, cement: any, row_idx: number) {
    }
    cb_status(status: string): any {
        
        if(status == "focus") {
            let input = document.getElementById(`${this.brick_id}`);
            if(input) {
                (<HTMLInputElement>input).focus();
                return true;
            }
        }

    }

    cb_validate(): boolean {
        let validates = true;
        let validation_message = "";
        if(this.validation) {
            let input = document.getElementById(`${this.brick_id}`) as HTMLInputElement;
            if(input) {
                let value = input.value;
                
                if(this.validation.required && (value === "" || value === null || value === undefined)) {
                    validates = false;
                    validation_message = (this.label || this.placeholder || "Value") + " is a required field.";
                }
                else if(this.validation.minlength > 0 && value.length < this.validation.minlength) {
                    validates = false;
                    validation_message = this.label + " must be at least " + this.validation.minlength + " characters.";
                }
                else if(this.validation.maxlength > 0 && value.length > this.validation.maxlength) {
                    validates = false;
                    validation_message = this.label + " may not exceed " + this.validation.maxlength + " characters.";
                }
                else if(this.validation.pattern !== "" && this.validation.pattern !== null && this.validation.pattern !== undefined) {
                    if(!new RegExp( '^(?:' + this.validation.pattern.trim() + ')$').test( value )) {
                        validates = false;
                        validation_message = this.validation.pattern_message;
                    }
                }
                else if(this.validation.invalidate_msg) {
                    validates = false;
                    validation_message = this.validation.invalidate_msg;
                }
            }

            let validation_message_container = document.getElementById(`${this.brick_id}$validation`);

            let input_wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
            if(input_wrap) {
                if(validates) {
                    input_wrap.classList.remove("c-input-validation-failed");

                    if(validation_message_container) {
                        validation_message_container.style.display = "none";
                        validation_message_container.innerHTML = "";
                    }
                }
                else {
                    input_wrap.classList.add("c-input-validation-failed");

                    if(validation_message_container) {
                        validation_message_container.innerHTML = validation_message;
                        validation_message_container.style.display = "block";
                    }
                }
            }
            
        }
        this.validate = true;
        return validates;
    }

    clearValidation() {
        let input_wrap = document.getElementById(`${this.brick_id}$wrap`) as HTMLInputElement;
        if(input_wrap) {
            input_wrap.classList.remove("c-input-validation-failed");
        }
        let validation_message_container = document.getElementById(`${this.brick_id}$validation`);
        if(validation_message_container) {
            validation_message_container.style.display = "none";
            validation_message_container.innerHTML = "";
        }
        this.validate = false;
    }

    cb_snapshot() {
        return { value: this.value };
    }
}
